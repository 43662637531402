import { IconButton, Box, ImageListItemBar } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useState } from 'react'
import { generatePath } from 'react-router-dom'
import { useCustomNavigation } from '../../../hooks/useCustomNavigate'
import { NavigationEnum } from '../../../utils/constants'
import styles from './MainAssetsCard.module.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CloseIcon from '@mui/icons-material/Close'

interface IProps {
  image?: string
  description?: string
  isFavorite?: boolean
  imageId?: number | string
  onDeleteImage?: (imageId: number | string) => void,
  isDeletable?: boolean
}

const HoverImageListItemBar = styled(ImageListItemBar)(({ theme }) => ({
  opacity: 0,
  transition: 'opacity 0.3s',
  '&:hover': {
    opacity: 1,
  },
  fontSize: 13,
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

export const MainAssetsCard = (props: IProps) => {
  const { image, description, imageId, onDeleteImage, isDeletable } = props
  const customNavigate = useCustomNavigation()

  const [openAddToLightbox, setOpenAddToLightbox] = useState(false)

  const handleNavigate = () => {
    const path = generatePath(NavigationEnum.ASSET_DETAILS, {
      assetName: `${description?.replaceAll(' ', '-')}-${imageId}` as string,
    })
    customNavigate(path as NavigationEnum, {
      imageId,
    })
  }

  const handleOpenAddToLightbox = () => {
    setOpenAddToLightbox(!openAddToLightbox)
  }

  const handleConfirmAddToLightbox = () => {
    handleOpenAddToLightbox()
  }

  const handleDeleteImage= () => {

    if(onDeleteImage && imageId) {
      onDeleteImage(imageId)
    }
  }

  return (
    <Box
      sx={{
        '&:hover': {
          transform: 'scale(1.01)',
          '& .MuiImageListItemBar-root': { opacity: 1 },
          '&::after': {
            content: '""',
            display: 'block',
            backgroundColor: 'rgba(0,0,0,0.15)',
            zIndex: 1,
          },
        },
      }}
    >
      <LazyLoadImage
        alt={description}
        style={{
          position: 'relative',
          height: '100%',
          width: '100%',
          objectFit: 'cover'
        }}
        onClick={() => handleNavigate()}
        src={`${image}`}
      />
      {isDeletable && <IconButton
          className={styles.closeButton}
          onClick={() => handleDeleteImage()}
          size='small'
          sx={{
            position: 'absolute',
            right: 5,
            top: 5,
            backgroundColor: '#c11b1e',
            opacity: 0.8,
            color: 'white',
          }}
        >
          <CloseIcon sx={{ width: 15, height: 15 }} />
        </IconButton>}
      <HoverImageListItemBar
        title={description}
      />
    </Box>
  )
}
