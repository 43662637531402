import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { JsonParam, useQueryParam, withDefault } from 'use-query-params'
import {
  resetFilters,
  setAdvancedSearch, setAiNetwork, setNumberOfItems,
  setOrientation,
  setSortBy,
  setTypes
} from '../../../../redux/reducers/searchReducer'
import { IFilters, initialFiltersConst } from '../../../../utils/constants'
import { FilterCard } from '../../../shared-components/card/FilterCard'
import { FilterChipsList } from '../../../shared-components/card/FilterChipsList'
import { CustomCheckbox } from '../../../shared-components/checkbox/CustomCheckbox'
import { CustomRadio } from '../../../shared-components/checkbox/CustomRadio'
import { SearchBar } from '../../../shared-components/search/SearchBar'
import { CustomTextfield } from '../../../shared-components/textfield/CustomTextField'
import styles from './HeaderSearchForm.module.scss'
import AppBar from '@mui/material/AppBar'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
interface IAdvancedSearch {
  date?: Date
  author?: string
  itemId?: string
}
export const HeaderSearchForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const initialFilters = withDefault(JsonParam, initialFiltersConst)
  const [filters, setFilters] = useQueryParam<IFilters>(
    'filters',
    initialFilters
  )
  const [advancedSearchLocal, setAdvancedSearchLocal] =
    useState<IAdvancedSearch>()
  const [aiText, setAiText] = useState(filters?.ai_network)
  const dispatch = useDispatch()


  const mapFiltersToTextForChips = (value: string | number) => {
    value = value.toString()
    switch (value) {
      case '10':
        return t('Per page', { count: 10 })
      case '20':
        return t('Per page', { count: 20 })
      case '50':
        return t('Per page', { count: 50 })
      case '100':
        return t('Per page', { count: 100 })
      case 'most_downloaded':
        return t('Most downloaded')
      case 'recently':
        return t('Recently')
      case 'all':
        return t('All')
      case 'landscape':
        return t('Landscape')
      case 'portrait':
        return t('Portrait')
      case 'editorial':
        return t('Editorial')
      case 'adult_content':
        return t('Adult content')
      case 'generated_by_ai':
        return t('Generated by AI')
      default:
        return value
    }
  }
  const toggleTypeFilter = (value: string) => {
    if (filters?.typeFilter?.includes(value)) {
      setFilters({
        ...filters,
        ai_network: '',
        typeFilter: filters?.typeFilter?.filter((item) => item !== value),
      })
      dispatch(setTypes(filters?.typeFilter?.filter((item) => item !== value)))
    } else {
      setFilters({
        ...filters,
        typeFilter: [...(filters?.typeFilter || []), value],
      })
      dispatch(setTypes([...(filters?.typeFilter || []), value]))
    }
  }
  const handleChangeFilterAdvancedSearch = () => {
    setFilters({
      ...filters,
      advancedSearch: advancedSearchLocal,
    })
    dispatch(setAdvancedSearch({...advancedSearchLocal}))
  }
  const handleSearchByAi= () => {
    dispatch(setAiNetwork(aiText || ''))
    setFilters({...filters, ai_network: aiText})
  }
  useEffect(() => {
    setAdvancedSearchLocal({
      ...filters.advancedSearch,
      date: filters.advancedSearch?.date
        ? new Date(filters.advancedSearch?.date)
        : undefined,
    })
  }, [])

  return (
    <AppBar position="sticky" sx={{backgroundColor: 'white', boxShadow: 'none'}}>
    <div className={styles.topContainer}>
      <div className={styles.searchBar}>
        <SearchBar />
      </div>

      <Grid
        container
        spacing={2}
        direction='row'
        className={styles.gridContainer}
      >
        <FilterCard summary={t('Sort by')}>
          <FormControl component='fieldset' color='error'>
            <RadioGroup
              defaultValue='recently'
              name='sortBy'
              value={filters?.sortByFilter}
              onChange={(e) => {
                setFilters({ ...filters, sortByFilter: e.target.value })
                dispatch(setSortBy(e.target.value))
              }}
            >
              <FormControlLabel
                value='most_downloaded'
                control={<CustomRadio />}
                label={t('Most downloaded')}
                className={styles.radiobox}
              />
              <FormControlLabel
                value='recently'
                control={<CustomRadio />}
                label={t('Recently')}
                className={styles.radiobox}
              />
            </RadioGroup>
          </FormControl>
        </FilterCard>
        <FilterCard summary={t('Type')}>
          <FormControl component='fieldset'>
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={filters?.typeFilter?.includes('editorial') ?? false}
                  onChange={() => toggleTypeFilter('editorial')}
                />
              }
              label={t('Editorial')}
              className={styles.checkbox}
            />
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={
                    filters?.typeFilter?.includes('adult_content') ?? false
                  }
                  onChange={() => toggleTypeFilter('adult_content')}
                />
              }
              label={t('Adult content')}
              className={styles.checkbox}
            />
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={
                    filters?.typeFilter?.includes('generated_by_ai') ?? false
                  }
                  onChange={() => {
                    toggleTypeFilter('generated_by_ai')
                  }}
                />
              }
              label={t('Generated by AI')}
              className={styles.checkbox}
            />
            {filters?.typeFilter?.includes('generated_by_ai') && (
              <>
              <CustomTextfield
                className={styles.textfield}
                size='small'
                name='ai_network'
                variant='outlined'
                label={t('AI Network')}
                value={aiText}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setAiText(e.target.value)
                }}
              />
                <Button
                  variant='contained'
                  className={styles.textfieldButton}
                  onClick={() => {
                    handleSearchByAi()
                  }}
                >
                  {t('Search')}
                </Button>
              </>
            )}
          </FormControl>
        </FilterCard>

        <FilterCard summary={t('Advanced Search')}>
          <CustomTextfield
            className={styles.textfield}
            size='small'
            name='item_id'
            variant='outlined'
            label={t('Item ID')}
            value={advancedSearchLocal?.itemId}

            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAdvancedSearchLocal((prev) => {
                return { ...prev, itemId: e.target.value }
              })
            }}
          />
          <CustomTextfield
            className={styles.textfield}
            size='small'
            name='author'
            variant='outlined'
            label={t('Author')}
            value={advancedSearchLocal?.author}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAdvancedSearchLocal((prev) => {
                return { ...prev, author: e.target.value }
              })
            }}
          />
          {/*<LocalizationProvider dateAdapter={AdapterDayjs}>*/}
          {/*  <DatePicker*/}
          {/*    sx={{zIndex: 9999}}*/}
          {/*    className={styles.datePicker}*/}
          {/*    label={t('Date')}*/}
          {/*    value={advancedSearchLocal?.date ? dayjs(advancedSearchLocal?.date) : null}*/}
          {/*    format='DD-MM-YYYY'*/}
          {/*    disableFuture*/}
          {/*    onChange={(newValue: any) => {*/}
          {/*      setAdvancedSearchLocal((prev: any) => {*/}
          {/*        return { ...prev, date: newValue ? dayjs(newValue).format('YYYY-MM-DD') : undefined}*/}
          {/*      })*/}
          {/*    }}*/}
          {/*    slots={{*/}
          {/*      textField: CustomTextfield,*/}
          {/*    }}*/}
          {/*    slotProps={{*/}
          {/*      textField: { size: 'small' },*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</LocalizationProvider>*/}

          <Button
            variant='contained'
            className={styles.textfieldButton}
            onClick={() => {
              handleChangeFilterAdvancedSearch()
            }}
          >
            {t('Search')}
          </Button>
        </FilterCard>
        <FilterCard summary={t('Orientation')}>
          <FormControl component='fieldset' color='error'>
            <RadioGroup
              defaultValue='all'
              name='orientation'
              value={filters?.orientation}
              onChange={(e) => {
                setFilters({ ...filters, orientation: e.target.value })
                dispatch(setOrientation(e.target.value === 'all' ? '': e.target.value))
              }}
            >
              <FormControlLabel
                value='all'
                control={<CustomRadio />}
                label={t('All')}
                className={styles.radiobox}
              />
              <FormControlLabel
                value='landscape'
                control={<CustomRadio />}
                label={t('Landscape')}
                className={styles.radiobox}
              />
              <FormControlLabel
                value='portrait'
                control={<CustomRadio />}
                label={t('Portrait')}
                className={styles.radiobox}
              />
            </RadioGroup>
          </FormControl>
        </FilterCard>
        <FilterCard summary={t('Items')}>
          <FormControl component='fieldset' color='error'>
            <RadioGroup
              defaultValue='a'
              name='per_page'
              value={filters.items}
              onChange={(e) => {
                setFilters({ ...filters, items: parseInt(e.target.value) })
                dispatch(setNumberOfItems(e.target.value))
              }}
            >
              <FormControlLabel
                value='10'
                control={<CustomRadio />}
                label={t('Per page', { count: 10 })}
                className={styles.radiobox}
              />
              <FormControlLabel
                value='20'
                control={<CustomRadio />}
                label={t('Per page', { count: 20 })}
                className={styles.radiobox}
              />
              <FormControlLabel
                value='50'
                control={<CustomRadio />}
                label={t('Per page', { count: 50 })}
                className={styles.radiobox}
              />
              <FormControlLabel
                value='100'
                control={<CustomRadio />}
                label={t('Per page', { count: 100 })}
                className={styles.radiobox}
              />
            </RadioGroup>
          </FormControl>
        </FilterCard>
        <Box sx={{alignItems: 'center', margin: '10px'}}>
          <Button onClick={() => {
            navigate({ pathname: '/assets'})
            dispatch(resetFilters())}} variant="contained" size="small" sx={{padding: 1}}>{t('remove_filters')}</Button>
        </Box>
      </Grid>
      <FilterChipsList
        isNoSearch={true}
        isVisibleChips={true}
        titles={[
          ...Object.values(filters)
            .flat()
            .filter((item) => item && typeof item !== 'object')
            .map((item) => mapFiltersToTextForChips(item)),
          ...Object.values(filters?.advancedSearch || '').map((item) =>
            mapFiltersToTextForChips(item.toString().split('T2')[0])
          ),
        ]}
      />
    </div>
    </AppBar>
  )
}
